<template>
    <div>
        <b-input-group :prepend="$t('horse.numero_transpondeur')">
            <template v-slot:append>
                <b-input-group-text>
                    <span>
                        <font-awesome-icon v-if="isWorking" :icon="['fas', 'spinner']" pulse />
                        <font-awesome-icon v-else-if="hasEditedTranspondeur" :icon="['fas', 'save']" v-on:click.prevent="saveTranspondeur" />
                        <font-awesome-icon v-else-if="hassavedTranspondeur" :icon="['fal', 'check']" />
                    </span>
                </b-input-group-text>
            </template>

            <!-- <b-form-input
                type="text"
                ref="horse_fiche_transpondeur"
                class="form-control"
                :placeholder="$t('horse.numero_transpondeur_ex')"
                v-model="horse_transpondeur"
                :readonly="!editable"
            /> -->

            <e-copybox 
                id="horse_transpondeur" 
                ref="horse_fiche_transpondeur" classes="mb-3" 
                :value.sync="horse_transpondeur" 
                @keyup="$emit('update:horse_prop_transpondeur', horse_transpondeur)"
                :readonly="!editable"
            ></e-copybox>

        </b-input-group>
    </div>
</template>

<script type="text/javascript">
	import HorseMixin from "@/mixins/Horse.js";

	export default {
		mixins: [HorseMixin],
		props: [
            'horse_id',
            'horse_prop_transpondeur',
            'editable'
		],
		data () {
			return {
                horse_orig_transpondeur: '',
                horse_transpondeur: '',
                isOpen: false,
                isWorking: false,
                prefixe_transpondeur: '250 25',
                saved: false,
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.horse_transpondeur = ''
                if(!this.horse_prop_transpondeur) {
                    return
                }
                else if(this.horse_prop_transpondeur == 'null') {
                    return
                }
                else {
                    this.horse_orig_transpondeur = this.horse_prop_transpondeur
                    this.horse_transpondeur = this.horse_prop_transpondeur
                }
			},
            openInput() {
                this.$refs.horse_fiche_transpondeur.focus()
            },
            async saveTranspondeur() {
                this.isWorking = true
                await this.updateHorseTranspondeur(this.horse_id, this.horse_transpondeur)
                this.horse_orig_transpondeur = this.horse_transpondeur
                this.saved = true
                this.isWorking = false
            }
		},
        computed: {
            hasEditedTranspondeur() {
                return this.horse_transpondeur != this.horse_orig_transpondeur
            },
            hassavedTranspondeur() {
                return this.saved
            }
        },
        watch: {
            horse_transpondeur() {
                this.saved = false
            }
        }
	}
</script>
